<template>
<header>
</header>
<main> 
    <img src="logo.png" alt="Siblamer logo">
    <h3 class="link"><a href="https://github.com/siblamer" target="_blank">My github :3</a></h3>
</main>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
text-decoration: none;
color: black;
font-weight: 400;
}
main {
position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 
}
img {
  height: 30vmin;
  pointer-events: none;
}
h1{
 font-size: calc(10px + 2vmin);
}
h2{
 font-size: calc(10px + 1.75vmin);
}
h3{
 font-size: calc(10px + 1.5vmin);
}

p{
 font-size: calc(10px + 1vmin);
}



</style>
